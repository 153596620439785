import React from 'react';
import { graphql } from 'gatsby';
import { Post, Layout } from '../components';

export const postQuery = graphql`
  query PostBySlug($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 90)
      fields {
        slug
      }
      frontmatter {
        title
        category
        service
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1200
              duotone: { highlight: "#000000", shadow: "#000000", opacity: 20 }
              quality: 90
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const PostTemplate = props => {
  const {
    data: { post }
  } = props;

  return (
    <Layout>
      <Post post={post} />
    </Layout>
  );
};

export default PostTemplate;
